<template>
  <div class="charge flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>充值查询</span>
      <span></span>
    </header>

    <main class="page-body flex-fill">
      <section class="card">
      <div>充值单号</div>
      <div class="tags">{{ order.sCode }}</div>
      <div>充值金额</div>
      <div class="amount">￥{{ order.fAmount }}</div>
      </section>
    </main>
  </div>

</template>
<script>
import { chargeResult } from '@/api/money.js';
export default {
  data() {
    return {
      selected: 0,
      amountOptions: [0.01, 200, 500, 1000],
      paymentOption: '1',
      amountInput: '',
      outlog: '',
      order: {},
    };
  },
  computed: {
    amount() {
      return this.amountInput || this.amountOptions[this.selected] || 0;
    },
  },
  mounted() {
    var cNo = this.$route.query.chargeNo;
    chargeResult({chargeNo: cNo}).then((res) => {
      if (res.Result) {
        //充值成功处理
        this.$toast.success('充值成功！');
      } else {
        //充值失败处理
        this.$toast.fail('充值失败');
      }

      this.order = res.DataDict['od'];
    });
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-header {
  border-bottom: 1px solid #e0e0e0;
}
.card {
  margin-bottom: rem(10px);
  background-color: #fff;
  padding: rem(40px);
  font-size: 16px;
}
.label {
  font-size: 16px;
  margin-bottom: rem(60px);
}
.tags {
  margin-bottom: rem(60px);
}
.amount-tag {
  width: rem(152px);
  height: rem(50px);
  line-height: rem(50px);
  text-align: center;
  background-color: #f7f7fc;
  border-radius: rem(10px);
  color: #9c9bb4;
  &.active {
    background-color: $theme-color;
    color: #fff;
  }
}
.payment-option {
  background: #f7f7fc;
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: rem(100px);
  line-height: rem(100px);
  padding: 0 rem(40px);
  justify-content: space-between;
  border: 2px solid transparent;
  color: #9c9bb4;
  &[aria-checked='true'] {
    border-color: #5c57bb;
    .van-icon {
      color: $theme-color;
    }
  }
  & + .payment-option {
    margin-top: rem(40px);
  }
  .van-icon {
    font-size: 18px;
  }
}
.page-footer {
  height: rem(118px);
  line-height: rem(118px);
  display: flex;
  align-items: center;
  padding: 0 rem(40px);
  background-color: #fff;
  .van-button {
    margin-left: auto;
    width: rem(240px);
    border-radius: rem(25px);
  }
  .amount {
    color: #dd1b1b;
    margin-left: rem(40px);
    font-size: 18px;
    font-weight: bold;
  }
}
.border-input {
  border-bottom: 1px solid #e0e0e0;
}
/deep/ .van-radio__label {
  display: flex;
  align-items: center;
  color: #9c9bb4;
  .van-icon {
    font-size: 24px;
    margin-right: rem(16px);
  }
}
</style>
